import styled, { css } from 'styled-components'
import { color, compose, flexbox, layout, space, typography } from 'styled-system'
import theme from '../../utils/theme'

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: ${p => (p.borderRadius ? p.borderRadius : '10px')};
  transition: ${theme.transition};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  position: relative;
  ${compose(space, layout, color, typography, flexbox)};

  ${p =>
    p.auto &&
    css`
      min-height: 0;
    `}

  ${p =>
    p.cardFull &&
    css`
      min-height: 100%;
    `}

  ${p =>
    p.flat &&
    css`
      box-shadow: none;
    `}

  ${p =>
    p.cardhidden &&
    css`
      overflow: hidden;
      will-change: transform;
    `}

  ${p =>
    p.shadow &&
    css`
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    `}

  ${p =>
    p.rounded &&
    css`
      border-radius: 4px;
    `}

  ${p =>
    p.bordered &&
    css`
      & {
        border: 1px solid ${theme.colors.darkGray};
        box-shadow: none;
      }
    `}
`

export const CardFull = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const CardContent = styled.div`
  flex: 1 1 auto;
  padding: 20px;
  ${layout};
  ${space};
  ${color};
  ${typography};
`

export const CardFooter = styled.div`
  flex-shrink: 0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 16px;

  ${space};
  ${color};
  ${typography};
`
