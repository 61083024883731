import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";
import theme from "../../utils/theme";
import { Card, CardContent } from "../UI/Card";
import { Row, Col } from "../UI/Grid";
import ScaledImage from "../UI/ScaledImage";
import { Heading, Paragraph } from "../UI/Typography";
import DownloadIcon from "../Icons/DownloadIcon";

const ReportCard = ({ name, url, thumbnail }) => {
  return (
    <Report
      bordered
      cardhidden
      as="a"
      href={url}
      target="_blank"
      rel="noopener noreferrer"
    >
      <ScaledImage scaleHeight={195} scaleWidth={345}>
        <GatsbyImage image={getImage(thumbnail)} alt="pdf" />
      </ScaledImage>
      <CardContent p={4}>
        <Row alignItems="center">
          <Col col="fill">
            <Heading size="h6">{`${name}.pdf`}</Heading>
          </Col>
          <Col col="auto">
            <Paragraph size="h3">
              <DownloadIcon />
            </Paragraph>
          </Col>
        </Row>
      </CardContent>
    </Report>
  );
};

const Report = styled(Card)`
  color: ${theme.colors.brand};

  ${Heading} {
    color: ${theme.colors.text};
  }
  &:hover {
    border-color: #000;
    color: ${theme.colors.brand};
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.12);
  }
`;

export default ReportCard;
