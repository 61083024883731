import { graphql, useStaticQuery } from "gatsby";

export const useReportsData = () => {
  const data = useStaticQuery(
    graphql`
      query ReportsQuery {
        allReportsJson {
          nodes {
            id
            reports_id
            name
            pdf {
              publicURL
            }
            thumbnail {
              childImageSharp {
                gatsbyImageData(formats: [AUTO, WEBP], layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    `
  );
  return data.allReportsJson.nodes;
};
