import React from "react";
import Seo from "../components/Layout/Seo";
import Site from "../components/Layout/Site";
import { navigate } from "gatsby";
import { isLoggedIn } from "../services/auth";
import { Section } from "../components/UI/Section";
import { Row, Col, Container } from "../components/UI/Grid";
import { Heading } from "../components/UI/Typography";
import { useReportsData } from "../hooks/use-reports-data";
import ReportCard from "../components/Cards/ReportCard";
import DefaultVector from "../components/Shared/DefaultVector";

const AnnualReports = ({ location }) => {
  const reports = useReportsData();

  if (
    typeof window !== `undefined` &&
    !isLoggedIn() &&
    location.pathname !== `/login`
  ) {
    navigate("/login");
    return null;
  }

  return (
    <Site>
      <Seo title="Annual Reports" />
      <Section py={{ _: 16, lg: 0 }}>
        <DefaultVector>
          <Container>
            <Heading size="display-4" as="h1" mb={10} textAlign="center">
              Annual Reports
            </Heading>

            {reports && (
              <Row justifyContent="center">
                {reports.map((r) => (
                  <Col col={{ lg: 4 }} key={r.id}>
                    <ReportCard
                      name={r.name}
                      url={r.pdf.publicURL}
                      thumbnail={r.thumbnail}
                    />
                  </Col>
                ))}
              </Row>
            )}
          </Container>
        </DefaultVector>
      </Section>
    </Site>
  );
};

export default AnnualReports;
