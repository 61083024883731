import React from "react";
import Icon from "../UI/Icon";

const DownloadIcon = () => {
  return (
    <Icon viewBox="0 0 30 30">
      <path d="M20.7375 11.25H18.75V5C18.75 4.3125 18.1875 3.75 17.5 3.75H12.5C11.8125 3.75 11.25 4.3125 11.25 5V11.25H9.2625C8.15 11.25 7.5875 12.6 8.375 13.3875L14.1125 19.125C14.6 19.6125 15.3875 19.6125 15.875 19.125L21.6125 13.3875C22.4 12.6 21.85 11.25 20.7375 11.25ZM6.25 23.75C6.25 24.4375 6.8125 25 7.5 25H22.5C23.1875 25 23.75 24.4375 23.75 23.75C23.75 23.0625 23.1875 22.5 22.5 22.5H7.5C6.8125 22.5 6.25 23.0625 6.25 23.75Z" />
    </Icon>
  );
};

export default DownloadIcon;
